<template>
<v-container>
    <div fluid :class="mobile ? 'shipMobile' : 'ship'">
    <v-row no-gutters>
        <v-col cols="12">
            <v-card tile flat class="first">
                <div class="centerText">
                    <div class="text-md-h3 text-h4" style="font-family: DIN_Regular, Arial, Verdana, sans-serif !important;">REVOLUTIONIZING LOGISTICS</div>
                    <div class="text-md-h5 text-h6" style="font-family: DIN_Regular, Arial, Verdana, sans-serif !important;">Utilizing AI and Big Data Precision</div>
                </div>
            </v-card>
        </v-col>
    </v-row>
    <div class="gradientRow">
    <v-row style="background-color: rgba(0, 0, 0, 0) !important;" >
        <v-card tile flat class="second d-flex justify-center align-center" style="flex-direction: column;">
            <v-row class="mb-8">
                <v-col cols="12" sm="12" md="12" lg="12">
                    <div id="canvasParent" class="canvasParent">
                        <canvas id="canvas1" class="canvas1"></canvas>
                    </div>
                    <div class="sentinel" id="sentinel"></div>
                    <span :class="mobile ? 'midMobile' : 'midText'" style="margin: 10vh 0 2vh 0">A COLLABORATIVE BUSINESS PLATFORM</span>
                    <v-row class="pa-0 d-flex justify-center">
                        <v-col cols="12" sm="10" md="9" lg="9" class=" px-5 center">
                            <span class="xSmallText" style="text-align: center;">
                                <div>In our world dependant on rapid global trade, the pressing challenge of outdated logistics technology hinders progress. That's where we come in.</div>
                                Introducing LØGLIVE – a powerful fusion of Artificial Intelligence and Big Data – redefining logistics by enhancing efficiency and reliability on a global scale. Harness the future of logistics with our cutting-edge solution.
                            </span>
                        </v-col>
                    </v-row>
                    <v-row :style="mobile ? '' : {'width': '90vw'}">
                        <v-col cols="12" sm="12" md="3" lg="3" :class="mobile ? 'center mb-4':'center'">
                            <v-card class="infoHoverCard  documents">
                                <div class="gradientDiv">
                                    <span class="cardTextHeader mb-1">Documentation</span>
                                    <span class="descript">Automated Data Capturing</span>
                                    <span class="descript">File Generation</span>
                                    <span class="descript">Version Control</span>
                                    <span class="descript">Cloud Storage</span>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3" :class="mobile ? 'center mb-4':'center pa-0'">
                            <v-card class="infoHoverCard transport">
                                <div class="gradientDiv">
                                    <span class="cardTextHeader mb-1">Transportation</span>
                                    <span class="descript">Driver Performance Measuring</span>
                                    <span class="descript">Integrated Vehicle Tracking</span>
                                    <span class="descript">Automatic Status Updates</span>
                                    <span class="descript">Smart Allocation</span>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3" :class="mobile ? 'center mb-4':'center pa-0'">
                            <v-card class="infoHoverCard booking">
                                <div class="gradientDiv">
                                    <span class="cardTextHeader mb-1">Bookings</span>
                                    <span class="descript">Automated Booking Process</span>
                                    <span class="descript">In-Depth Container Overview</span>
                                    <span class="descript">Seamless Pre-Advising</span>
                                    <span class="descript">Instant Client Reports</span>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3" :class="mobile ? 'center mb-4':'center pa-0'">
                            <v-card class="infoHoverCard shipment">
                                <div class="gradientDiv">
                                    <span class="cardTextHeader mb-1">Shipments</span>
                                    <span class="descript">Vessel Tracking</span>
                                    <span class="descript">Port Monitoring</span>
                                    <span class="descript">Telemetry Insights</span>
                                    <span class="descript">Stack Dates </span>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-- <v-row no-gutters>
                <v-col cols="12">
                    <div class="midText mb-8">
                        <div class="text-md-h2 text-h4">One System</div>
                        <div class="text-md-h4 text-h5">Complete Logistical Control</div>
                    </div>
                    <div>
                        <v-img class="d-felx mx-auto" :max-width="mobile ? '20%' : '60%'" style="box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 10px -10px, rgba(0, 0, 0, 0.3) 0px 15px 10px -10px;" src="../../public/LogliveMock.png"></v-img>
                    </div>
                </v-col>
            </v-row> -->

            <!-- <v-row>
                <v-col cols="12">
                    <div style="height: inherit; width: inherit; display: flex; justify-content: center; align-items: center;">
                        <div style="width: 65vw; height: 60vh; display: flex; justify-content: center; align-items: center; flex-direction: column">
                            <v-row>
                                <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center">
                                    <span class="bigText">A PLATFORM FOR THE FUTURE</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6" lg="6" class="xSmallText">By leveraging AI and big data, our platform offers a wealth of invaluable insights and optimizations that help businesses thrive in a competitive market. The platform has already made a significant impact, servicing around 35% of the South African industry as well as assisting governments globally with verification of information, often without the stakeholders even realizing it.</v-col>
                                <v-col cols="12" md="6" lg="6" class="xSmallText">In addition to the technological prowess of our platform, we recognize the importance of fostering a social community where businesses can collaborate, share knowledge, and form strategic partnerships. Our platform offers an interactive and user-friendly environment where stakeholders can communicate, engage, and work together to overcome the challenges they face collectively.</v-col>
                                <v-col cols="12" md="6" lg="6" class="xSmallText">One of the key features of our platform is the utilization of multiple data sources, which enables us to validate and cross-reference information to ensure the highest level of accuracy. With this approach, we can provide real-time updates, predictive analytics, and tailored recommendations, empowering businesses to make data-driven decisions with confidence. In turn, this leads to increased efficiency, reduced costs, and ultimately, a more sustainable and profitable industry.</v-col>
                                <v-col cols="12" md="6" lg="6" class="xSmallText">As a result, our platform not only streamlines the logistics process but also fosters a spirit of collaboration and innovation that drives the industry forward. In this ever-evolving landscape, staying ahead of the curve is essential, and our platform provides the tools and resources necessary for businesses to excel.</v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row> -->

            <!-- <v-row>
                <v-col cols="12">
                    <div class="bigText text-h3 text-center">A PLATFORM FOR THE FUTURE</div>
                </v-col>
                <v-col>
                    <v-container style="max-width:66.66%">
                    <v-row>
                        <v-col cols="12" md="6" class="xSmallText">By leveraging AI and big data, our platform offers a wealth of invaluable insights and optimizations that help businesses thrive in a competitive market. The platform has already made a significant impact, servicing around 35% of the South African industry as well as assisting governments globally with verification of information, often without the stakeholders even realizing it.</v-col>
                        <v-col cols="12" md="6" class="xSmallText">In addition to the technological prowess of our platform, we recognize the importance of fostering a social community where businesses can collaborate, share knowledge, and form strategic partnerships. Our platform offers an interactive and user-friendly environment where stakeholders can communicate, engage, and work together to overcome the challenges they face collectively.</v-col>
                        <v-col cols="12" md="6" class="xSmallText">One of the key features of our platform is the utilization of multiple data sources, which enables us to validate and cross-reference information to ensure the highest level of accuracy. With this approach, we can provide real-time updates, predictive analytics, and tailored recommendations, empowering businesses to make data-driven decisions with confidence. In turn, this leads to increased efficiency, reduced costs, and ultimately, a more sustainable and profitable industry.</v-col>
                        <v-col cols="12" md="6" class="xSmallText">As a result, our platform not only streamlines the logistics process but also fosters a spirit of collaboration and innovation that drives the industry forward. In this ever-evolving landscape, staying ahead of the curve is essential, and our platform provides the tools and resources necessary for businesses to excel.</v-col>\
                    </v-row>
                </v-container>
                </v-col>
            </v-row> -->
        </v-card>
    </v-row>

    <v-row no-gutters class="system">
        <v-col cols="12">
            <div class="midText mb-8">
                <div class="text-md-h2 text-h4">One System</div>
                <div class="text-md-h4 text-h5">Complete Logistical Control</div>
            </div>
            <div>
                <v-img class="d-felx mx-auto" :max-width="mobile ? '90%' : '60%'" style="box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 10px -10px, rgba(0, 0, 0, 0.3) 0px 15px 10px -10px;" src="../../public/LogliveMock.png"></v-img>
            </div>
        </v-col>
        <!-- <v-col cols="12" sm="12" md="12" lg="12">
                <div style="height: inherit; width: inherit; display: flex; justify-content: center; align-items: center;">

                    <div style="width: 65vw; height: 60vh; display: flex; justify-content: center; align-items: center; flex-direction: column">
                        <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center">
                                <span class="bigText">A PLATFORM FOR THE FUTURE</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" lg="6" class="xSmallText">By leveraging AI and big data, our platform offers a wealth of invaluable insights and optimizations that help businesses thrive in a competitive market. The platform has already made a significant impact, servicing around 35% of the South African industry as well as assisting governments globally with verification of information, often without the stakeholders even realizing it.</v-col>
                            <v-col cols="12" md="6" lg="6" class="xSmallText">In addition to the technological prowess of our platform, we recognize the importance of fostering a social community where businesses can collaborate, share knowledge, and form strategic partnerships. Our platform offers an interactive and user-friendly environment where stakeholders can communicate, engage, and work together to overcome the challenges they face collectively.</v-col>
                            <v-col cols="12" md="6" lg="6" class="xSmallText">One of the key features of our platform is the utilization of multiple data sources, which enables us to validate and cross-reference information to ensure the highest level of accuracy. With this approach, we can provide real-time updates, predictive analytics, and tailored recommendations, empowering businesses to make data-driven decisions with confidence. In turn, this leads to increased efficiency, reduced costs, and ultimately, a more sustainable and profitable industry.</v-col>
                            <v-col cols="12" md="6" lg="6" class="xSmallText">As a result, our platform not only streamlines the logistics process but also fosters a spirit of collaboration and innovation that drives the industry forward. In this ever-evolving landscape, staying ahead of the curve is essential, and our platform provides the tools and resources necessary for businesses to excel.</v-col>
                        </v-row>
                    </div>
                </div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center" style="flex-direction: column;">
            <div style="height: 160px; width: 160px; margin-top: 10vh;">
                <v-img src="../../public/Loglive_512x512.png"></v-img>
            </div>
            <span class="logoBig">LØGLIVE</span>
            <v-btn style="margin-top: 15vh" large @click="goTo('contact')">CONTACT US</v-btn>
        </v-col> -->
    </v-row>


    <v-row class="my-16 py-16">
        <v-col cols="12">
            <div class="bigText text-h3 text-center">A PLATFORM FOR THE FUTURE</div>
        </v-col>
        <v-col>
            <v-container :style="mobile ? 'max-width:90%' : 'max-width:66.66%' ">
            <v-row>
                <v-col cols="12" md="6" class="xSmallText">By leveraging AI and big data, our platform offers a wealth of invaluable insights and optimizations that help businesses thrive in a competitive market. The platform has already made a significant impact, servicing around 35% of the South African industry as well as assisting governments globally with verification of information, often without the stakeholders even realizing it.</v-col>
                <v-col cols="12" md="6" class="xSmallText">In addition to the technological prowess of our platform, we recognize the importance of fostering a social community where businesses can collaborate, share knowledge, and form strategic partnerships. Our platform offers an interactive and user-friendly environment where stakeholders can communicate, engage, and work together to overcome the challenges they face collectively.</v-col>
                <v-col cols="12" md="6" class="xSmallText">One of the key features of our platform is the utilization of multiple data sources, which enables us to validate and cross-reference information to ensure the highest level of accuracy. With this approach, we can provide real-time updates, predictive analytics, and tailored recommendations, empowering businesses to make data-driven decisions with confidence. In turn, this leads to increased efficiency, reduced costs, and ultimately, a more sustainable and profitable industry.</v-col>
                <v-col cols="12" md="6" class="xSmallText">As a result, our platform not only streamlines the logistics process but also fosters a spirit of collaboration and innovation that drives the industry forward. In this ever-evolving landscape, staying ahead of the curve is essential, and our platform provides the tools and resources necessary for businesses to excel.</v-col>\
            </v-row>
        </v-container>
        </v-col>
    </v-row>

    

    <!-- <v-row class="my-16">
        <v-col cols="12" class="d-flex justify-center align-center py-16" style="flex-direction: column;">
                <v-img max-width="160" src="../../public/Loglive_512x512.png"></v-img>
            <span class="logoBig">LØGLIVE</span>
            <v-btn class="mt-16" large @click="goTo('contact')">CONTACT US</v-btn>
        </v-col>
    </v-row> -->

    <FooterComponent />
</div>
</div>
</v-container>
</template>

<script>
import FooterComponent from './FooterComponent.vue';
export default {
    components: {
        FooterComponent
    },

    data() {
        return {
            mobile: false,
            canvas: null,
            ctx: null,
            listEndObserver: null,
            animationFrameId: null,
            year: new Date().getFullYear(),
            mousePosition: {
                x: 0,
                y: 0
            },
            dots: {
                nb: 250,
                distance: 150,
                d_radius: 150,
                array: []
            }
        };
    },
    methods: {
        goTo(route) {
            if (this.$route.path !== `/${route.toLowerCase()}`) {
                this.$router.push({ path: `/${route.toLowerCase()}` })
                cancelAnimationFrame(this.animationFrameId);
                this.dots.array = [];

            }
        },
        createColorStyle(r, g, b) {
            return 'rgba(' + r + ',' + g + ',' + b + ', 0.4)';
        },
        averageColorStyles() {
            return this.createColorStyle('fff', 'fff', 'fff');
        },
        Color() {
            this.r = 255
            this.g = 255
            this.b = 255
            this.style = this.createColorStyle(this.r, this.g, this.b);
        },
        Dot() {
            this.x = Math.random() * this.canvas.width;
            this.y = Math.random() * this.canvas.height;
            this.vx = -.5 + Math.random();
            this.vy = -.5 + Math.random();
            this.radius = Math.random() * 2;
            this.color = new this.Color();
        },
        drawDot(dot) {
            this.ctx.beginPath();
            this.ctx.fillStyle = dot.color.style;
            this.ctx.arc(dot.x, dot.y, dot.radius, 0, Math.PI * 2, false);
            this.ctx.fill();
        },
        createDots() {
            for (let i = 0; i < this.dots.nb; i++) {
                this.dots.array.push(this.createDot());
            }
        },
        createColor() {
            const r = 255
            const g = 255
            const b = 255
            const style = this.createColorStyle(r, g, b);
            return { r, g, b, style };
        },
        createDot() {
            const x = Math.random() * this.canvas.width;
            const y = Math.random() * this.canvas.height;
            const vx = -.5 + Math.random();
            const vy = -.5 + Math.random();
            const radius = Math.random() * 2;
            const color = this.createColor();
            return { x, y, vx, vy, radius, color };
        },
        moveDots() {
            if (this.dots.array !== null && this.dots.array.length > 0) {
                for (let i = 0; i < this.dots.nb; i++) {
                    var dot = this.dots.array[i];
                    if (dot.y < 0 || dot.y > this.canvas.height) {
                        dot.vy = -dot.vy;
                    } else if (dot.x < 0 || dot.x > this.canvas.width) {
                        dot.vx = -dot.vx;
                    }
                    dot.x += dot.vx;
                    dot.y += dot.vy;
                }
            }
        },
        isInViewport() {
            const rect = this.canvas.getBoundingClientRect();
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
            const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

            return (
                rect.bottom > 0 &&
                rect.right > 0 &&
                rect.top < viewportHeight &&
                rect.left < viewportWidth
            );
        },

        setUpInterSectionObserver() {
            let options = {
                // root: document.getElementById('canvasParent'),
                margin: "20px",
            };
            this.listEndObserver = new IntersectionObserver(
                this.handleIntersection,
                options
            );
            this.listEndObserver.observe(document.getElementById('canvas1'));
        },

        handleIntersection([entry]) {
            if (entry.isIntersecting) {
                this.animationFrameId = requestAnimationFrame(this.animateDots.bind(this));
            }
        },

        connectDots() {
            if (this.dots.array.length > 0 && this.dots.array !== null) {

                for (let i = 0; i < this.dots.nb; i++) {
                    for (let j = 0; j < this.dots.nb; j++) {
                        let i_dot = this.dots.array[i];
                        let j_dot = this.dots.array[j];

                        if ((i_dot.x - j_dot.x) < this.dots.distance && (i_dot.y - j_dot.y) < this.dots.distance && (i_dot.x - j_dot.x) > -this.dots.distance && (i_dot.y - j_dot.y) > -this.dots.distance) {
                            if ((i_dot.x - this.mousePosition.x) < this.dots.d_radius && (i_dot.y - this.mousePosition.y) < this.dots.d_radius && (i_dot.x - this.mousePosition.x) > -this.dots.d_radius && (i_dot.y - this.mousePosition.y) > -this.dots.d_radius) {
                                this.ctx.beginPath();
                                this.ctx.strokeStyle = this.averageColorStyles(i_dot, j_dot);
                                this.ctx.moveTo(i_dot.x, i_dot.y);
                                this.ctx.lineTo(j_dot.x, j_dot.y);
                                this.ctx.stroke();
                                this.ctx.closePath();
                            }
                        }
                    }
                }
            }
        },
        drawDots() {
            if (this.dots.array.length > 0 && this.dots.array !== null) {

                for (let i = 0; i < this.dots.nb; i++) {
                    var dot = this.dots.array[i];
                    this.drawDot(dot);
                }
            }
        },
        animateDots() {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.moveDots();
            this.connectDots();
            this.drawDots();
            if (this.isInViewport()) {
                this.animationFrameId = requestAnimationFrame(this.animateDots.bind(this));
            } else {
                cancelAnimationFrame(this.animationFrameId);
            }
        },
        updateMousePosition(event) {
            this.mousePosition.x = event.clientX;
            this.mousePosition.y = event.clientY;
        },
        resetMousePosition() {
            this.mousePosition.x = this.canvas.width / 2;
            this.mousePosition.y = this.canvas.height / 2;
        }
    },
    beforeMount() {
        this.mobile = this.$vuetify.breakpoint.mobile;
    },
    watch: {
        '$vuetify.breakpoint.mobile': function () {
            this.mobile = this.$vuetify.breakpoint.mobile;
        }
    },
    destroyed() {
        cancelAnimationFrame(this.animationFrameId);
        this.dots.array = [];
        if (this.listEndObserver) {
            this.listEndObserver.disconnect();
        }
    },
    unmount() {
        cancelAnimationFrame(this.animationFrameId);
        this.dots.array = [];
    },
    mounted() {
        if(this.mobile) return;
        this.canvas = document.getElementById('canvas1');
        this.canvas.addEventListener('mousemove', this.updateMousePosition);
        this.setUpInterSectionObserver();
        this.ctx = this.canvas.getContext('2d');
        this.canvas.width = '1920';
        this.canvas.height = '968';
        this.ctx.lineWidth = .3;
        this.ctx.strokeStyle = this.createColor().style;
        this.mousePosition = {
            x: 30 * this.canvas.width / 100,
            y: 30 * this.canvas.height / 100
        };
        this.createDots();
        // this.animationFrameId = requestAnimationFrame(this.animateDots.bind(this));
    }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.pageCard {
    height: 100vh;
    width: 100vw;
}

.canvas1 {
    position: absolute;
    background: rgba(0, 0, 0, 0) !important;
    height: inherit;
    z-index: 10;
    width: inherit;
}

.canvasParent {
    position: absolute;
    z-index: -1;
    height: 150vh;
    width: 100vw;
}

.headInfo {
    font: 700 20px/24px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(184, 184, 184);
    display: block;
}

.infoText {
    font: 400 16px/20px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(184, 184, 184);
    display: block;
}

.sentinel {
    height: 20px;
    width: 100%;
}

.bottomInfo {
    background-color: rgba(0, 0, 0, 1) !important;
    height: 24vh;
    width: 100vw;
    position: relative;
    bottom: 0;
}

.ship {
    background-color: black !important;
    background: url(../../public/BoatFinal.png) no-repeat 0px 5vh fixed;
    background-size: auto 100vh;
    /* position: absolute; */
    height: auto;
    z-index: 1;
    width: 100vw;
}

.center,
.centerCol {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centerCol {
    flex-direction: column;
}

.shipMobile {
    background-color: black !important;
    background: url(../../public/BoatFinal.png) no-repeat -50vw 20vh fixed;
    background-size: auto 80vh;
    width: 100vw;
    height: auto;
    position: absolute;

}

.first {
    background-color: transparent !important;
    background: transparent;
    /* width: 100vw; */
    height: 85vh;
}
.gradientRow {
    background: radial-gradient(circle, #2d3339, #292d32, #25272b, #202124, #1b1b1d, #181819, #141415, #101010, #0d0d0d93, #09090913, #04040400, #00000000) !important;
}
.second {
    background: rgba(0, 0, 0, 0) !important;
    /* position: relative; */
    z-index: 0;
    height: auto;
    min-height: 100vh;
    width: 100vw;
}

.system {
    background: transparent !important;
    position: relative; 
    /* background-image: radial-gradient(circle, #2d3339, #292d32, #25272b, #202124, #1b1b1d, #181819, #141415, #101010, #0d0d0d93, #09090927, #04040400, #00000000); */
    z-index: 0;
    height: 100vh;
    min-height: 100vh;
    width: 100vw;
    margin-top: 10rem
}

.third {
    background-color: rgba(0, 0, 0, 0) !important;
    height: 100vh;
    width: 100vw;
}

.fourth,
.fourthMobile {
    background-color: #00000000 !important;
    opacity: 0.8;
}

.fourthMobile {
    height: 100vh;
}

.fifth {
    background-color: rgba(0, 0, 0, 0.0) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo,
.logoBig {
    display: inline-block;
    letter-spacing: 4px;
    color: #fff;
    cursor: pointer;
}

.logo {
    font: 400 20px/26px Roboto, Helvetica, sans-serif;
    font-size: 20px;

}

.logoBig {
    font: 400 48px/56px Roboto, Helvetica, sans-serif;
    font-size: 60px;
}

.bigText {
    font: 700 42px/54px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(204, 204, 204);
}

.bigMobile {
    font: 700 30px/34px DIN_Regular, Arial, Verdana, sans-serif;
    text-align: center;
    color: rgb(204, 204, 204);
}

.midText {
    font: 700 32px/32px DIN_Regular, Arial, Verdana, sans-serif;
    letter-spacing: -1px;
    line-height: 28px;
    color: rgb(204, 204, 204);
    display: block;
    text-transform: uppercase;
    text-align: center;
}

.midMobile {
    font: 700 28px/32px DIN_Regular, Arial, Verdana, sans-serif;
    letter-spacing: -1px;
    line-height: 28px;
    text-align: center;
    color: rgb(204, 204, 204);
    display: block;
    text-transform: uppercase;
}

.smallText {
    font: 100 18px/20px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(204, 204, 204);
}

.xSmallText {
    font: 100 16px/12px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(204, 204, 204);
    line-height: 25px;
}

.mobileMargin {
    margin: 0 10vw;
}

.centerText {
    color: rgb(204, 204, 204);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40%;
    text-align: center;
}

.endText {
    color: rgb(204, 204, 204);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    height: 40vh;
    padding: 6vh 12vw 0px 0px;

}

.cardTextHeader {
    font: 700 28px/49px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(204, 204, 204);
    letter-spacing: -1px;
    line-height: 28px;
    text-transform: uppercase;
}

.infoHoverCard {
    height: 60vh;
    width: 100%;
    margin: 0 1vw;
    background-color: rgba(0, 0, 0, 0);
}

.gradientLine,
.gradientLineReverse {
    height: 2px;
    width: 100vw;
}

.gradientLine {
    background-image: linear-gradient(to right, #000000, #151515, #232323, #323232, #414141, #555555, #696969, #7e7e7e, #9d9d9d, #bcbcbc, #dddddd, #ffffff);
}

.gradientLineReverse {
    background-image: linear-gradient(to left, #000000, #1a1a1a, #2e2e2e, #434343, #595959, #6d6d6d, #828282, #989898, #b1b1b1, #cacaca, #e4e4e4, #ffffff);
}

.gradientDiv {
    padding: 20px 15px;
    height: inherit;
    width: inherit;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to bottom, #000000f6, #060606ec, #0b0b0bc7, #10101073, #1414144f, #17171734, #1a1a1a33, #1d1d1d00, #2121211e, #25252500, #2a2a2a00, #2e2e2e00);
}

.transport,
.shipment,
.booking,
.documents {
    position: relative;
    overflow: hidden;
}

.transport::before,
.shipment::before,
.booking::before,
.documents::before {
    content: "";
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.8;
    color: rgb(255, 255, 255);
    transform: scale(1);
    transition: filter 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
}

.transport::before {
    background: url(../../public/trucks.jpg) no-repeat 0px 0px;
    background-size: 100% 100%;
}

.shipment::before {
    background: url(../../public/vessel.jpg) no-repeat -20px 0px;
    background-size: 108% 105%;
}

.booking::before {
    background: url(../../public/bookings.png) no-repeat 0px 0px;
    background-size: 120% 105%;

}

.descript {
    font: 100 16px/20px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(224, 224, 224);
    line-height: 22px;
}

.documents::before {
    background: url(../../public/yard.png) no-repeat -20px -10px;
    background-size: 108% 105%;

}

.transport:hover::before,
.shipment:hover::before,
.booking:hover::before,
.documents:hover::before {
    transform: scale(1.1);
    opacity: 1;
}
</style>
