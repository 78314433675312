<template>
    <v-container fluid class="mainCard">
            <v-row class="mb-12 d-flex justify-center align-center px-0">
                <v-col cols="12" sm="12" md="12" lg="12" class="d-flex align-center justify-center">
                    <span class="bigText">Contact Us</span>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-center align-center pb-16 px-8">
                <v-col cols="12" sm="8" md="6" lg="6" class="d-flex justify-center align-center">
                    <v-row class="pa-0 d-flex justify-center align-center">
                        <v-col cols="12" sm="12" md="6" lg="6" class="pa-0">
                            <v-text-field outlined hide-details v-model="formData.name" label="Name"></v-text-field>
                            <v-text-field outlined hide-details v-model="formData.designation" label="Designation"></v-text-field>
                            <v-text-field outlined hide-details v-model="formData.mobileNumber" label="Mobile Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" class="pa-0">
                            <v-text-field outlined hide-details v-model="formData.companyName" label="Company Name"></v-text-field>
                            <v-text-field outlined hide-details v-model="formData.country" label="Country"></v-text-field>
                            <v-text-field outlined hide-details v-model="formData.emailAddress" label="Email Address"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" class="pa-0">
                            <v-select outlined hide-details v-model="formData.sectorIndustry" label="Sector/Industry" :items="sectors"></v-select>
                            <v-select outlined hide-details v-model="formData.organisationType" label="Organization Type" :items="organisationTypes"></v-select>
                            <v-select outlined hide-details v-model="formData.solutionProvider" label="Solution Provider" :items="solutionProviders"></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center">
                            <v-btn :loading="loadingData" @click="submitContact">Submit</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        
        <!-- <v-card class="bottomInfo">
            <v-row class="d-flex justify-center" style="height: 20vh;">
                <v-col cols="12" sm="2" lg="2" md="2" class="d-flex justify-center align-center" style="flex-direction: column;">
                        <div style="height: 64px; width: 64px;">
                            <v-img src="../../public/Loglive_64x64.png"></v-img>
                        </div>
                        <span class="logo">LØGLIVE</span>
                </v-col>
                <v-col cols="12" sm="2" lg="2" md="2" class="pt-12">
                    <span class="headInfo mb-5">Company</span>
                    <span class="infoText">LOGLIVE LTD</span>
                    <span class="infoText">Company Reg No: 14430181</span>
                </v-col>
                <v-col cols="12" sm="2" lg="2" md="2" class="pt-12">
                    <span class="headInfo mb-5">South Africa</span>
                    <span class="infoText">Unit 4, 1 Lions Pride Building,</span>
                    <span class="infoText">Zandwyk Industrial Park,</span>
                    <span class="infoText mb-5">Old Paarl Road, Paarl, 7646, South Africa</span>
                    <span class="infoText">Tel: +27 66 20 200 70</span>
                </v-col>
                <v-col cols="12" sm="2" lg="2" md="2" class="pt-12">
                    <span class="headInfo mb-5">United Kingdom</span>
                    <span class="infoText">9 PLOUGHMANS CROST</span>
                    <span class="infoText">Ploughmans Croft, Newport,</span>
                    <span class="infoText mb-5">Shropshire, TF10 7XP, England</span>                
                    <span class="infoText">+44 20 3996 3855</span>
                </v-col>
            </v-row>
        </v-card>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center py-0">
                <span class="infoText">Copyright © 2024 LOGLIVE LTD. All Rights Reserved.</span>
            </v-col>
        </v-row>     -->
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
        >
          Your form has been submitted!

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>

        <FooterComponent :show-contact-button="false"></FooterComponent>
    </v-container>
</template>

<script>
import FooterComponent from './FooterComponent.vue';
import axios from 'axios'

class TemplateData {
    constructor() {
        this.name = '';
        this.designation = '';
        this.mobileNumber = '';
        this.companyName = '';
        this.country = '';
        this.emailAddress = '';
        this.sectorIndustry = '';
        this.organisationType = '';
        this.solutionProvider = '';
    }
}
export default {
    components: {
        FooterComponent
    },

    data: () => ({
        loadingData: false,
        snackbar: false,
        timeout: 3000,
        formData: new TemplateData(),
        sectors: ['Fresh Produce', 'Mining', 'FMCG', 'Pharmaceuticals', 'Automotive', 'Other' ],
        organisationTypes: ['Exporter', 'Importer', 'Freight Forwarder', 'Shipping Line',  'Governing Body', 'IT', 'Other' ],
        solutionProviders: ['Bank', 'Farmer / Producer', 'Cold Store', 'Mine']
    }),

    methods: {
        submitContact() {
            this.loadingData = true
            this.sendFormData()
            setTimeout(() => {
                this.formData = new TemplateData()
                this.snackbar = true
                this.loadingData = false
            }, 3000);
        },

        async sendFormData() {
            const data = new FormData()
            data.append('entry.1088862252', this.formData.name)
            data.append('entry.1240860700', this.formData.companyName)
            data.append('entry.1058634178', this.formData.country)
            data.append('entry.968155760', this.formData.designation)
            data.append('entry.14870178', this.formData.emailAddress)
            data.append('entry.417612004', this.formData.mobileNumber)
            data.append('entry.1422689970', this.formData.sectorIndustry)
            data.append('entry.1334962014', this.formData.organisationType)
            data.append('entry.877113609', this.formData.solutionProvider)

            try {
                const result = await axios.post(
                    'https://docs.google.com/forms/u/0/d/e/1FAIpQLScgG9OVFPYEQvczvMq-42cBhs03pHI2RWJuVvcpYSEzBXrO_w/formResponse',
                    data,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }    
                )

                console.log('result', result)
            } catch (e) {
                console.log('failed', e)
            }
            
        }
    }
}
</script>

<style scoped>

.bottomInfo {
    background-color: rgba(0, 0, 0, 1) !important;
    height: 24vh;
    width: 100vw;
    position: relative;
    bottom: 0;
}
.logo {
  display: inline-block;
  letter-spacing: 4px;
  font-size: 20px;
  font: 24px/26px Roboto,Helvetica,sans-serif;
  color: #fff;
  cursor: pointer;
}
.mainCard {
    background-color: rgb(0, 0, 0) !important;
}

.headInfo {
  font: 700 20px/24px DIN_Regular,Arial,Verdana,sans-serif;
  color: rgb(184, 184, 184);
  display: block;
}

.infoText {
  font: 400 16px/20px DIN_Regular,Arial,Verdana,sans-serif;
  color: rgb(184, 184, 184);
  display: block;

}
.v-text-field{
    background-color: rgb(34, 34, 34) !important;
    margin: 1vh 1vw;
    color: rgb(224, 224, 224) !important;
    border: 0px solid rgb(138, 138, 138) !important;
    font-size: 14px;
  }

  .v-text-field ::v-deep input, .v-text-field ::v-deep .v-label, .v-text-field ::v-deep .v-input__control, .v-select ::v-deep .v-select__selection {
    color: rgb(224, 224, 224) !important;
  }
  .v-text-field ::v-deep fieldset {
    border: 1px solid rgb(59, 59, 59) !important;
  }
.first {
    background: rgb(0, 0, 0, 0);
    height: 67vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.bigText {
  font: 700 42px/54px DIN_Regular,Arial,Verdana,sans-serif;
  color: rgb(204, 204, 204);

}
</style>