<template>
    <v-card class="mainCard">
        <v-card class="first">
            <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center">
                    <span class="bigText">OUR STORY</span>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
                <v-col cols="12" sm="12" md="3" lg="3">
                    <span class="midText">" Leading the way in Technology and Information. "</span>
                </v-col>
                <v-col cols="12" sm="12" md="5" lg="5"></v-col>
            </v-row>
        </v-card>
    </v-card>
</template>

<script>
export default {

}

</script>

<style scoped>
.mainCard {
    background-color: black !important;
}

.first {
    background: rgb(0, 0, 0, 0);
    height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
.bigText {
  font: 700 42px/54px DIN_Regular,Arial,Verdana,sans-serif;
  color: rgb(204, 204, 204);
}

.midText {
  font: 700 32px/32px DIN_Regular,Arial,Verdana,sans-serif;
  letter-spacing: -1px;
  line-height: 28px;
  color: rgb(204, 204, 204);
  display: block;
  /* text-transform: uppercase; */
}

.smallText {
  font: 100 18px/20px DIN_Regular,Arial,Verdana,sans-serif;
  color: rgb(204, 204, 204);
}

.xSmallText {
  font: 100 16px/12px DIN_Regular,Arial,Verdana,sans-serif;
  color: rgb(204, 204, 204);
  line-height: 25px;
}

.centerText {
  color: rgb(204, 204, 204);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
}
</style>