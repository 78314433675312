<template>
  <div>
    <v-row class="text-center">
        <v-col cols="12">
            <v-row class="d-flex justify-center">
                <v-col cols="12" sm="2" lg="2" md="2" class="d-flex justify-center align-center" style="flex-direction: column;">
                    <v-img contain src="../../public/Loglive_64x64.png"></v-img>
                    <span class="logo" :style="showContactButton ? '' : {'margin': '-40px 0 0 0'}">LØGLIVE</span>
                    <v-btn v-if="showContactButton" class="mt-8" small @click="goTo('contact')">CONTACT US</v-btn>
                    <v-btn icon class="mt-2" href="https://www.linkedin.com/company/loglive/" target="_blank">
                        <v-img  height="54" width="54"  src="../../public/linkedinicon.png"></v-img>
                    </v-btn>
                </v-col>
                <v-col cols="12" md="2" class="pt-12">
                    <span class="headInfo mb-5">Company</span>
                    <span class="infoText">LOGLIVE LTD</span>
                    <span class="infoText">Company Reg No: 14430181</span>
                </v-col>
                <v-col cols="12" md="2" class="pt-12">
                    <span class="headInfo mb-5">South Africa</span>
                    <span class="infoText">Unit 4, 1 Lions Pride Building,</span>
                    <span class="infoText">Zandwyk Industrial Park,</span>
                    <span class="infoText mb-5">Old Paarl Road, Paarl, 7646, South Africa</span>
                    <span class="infoText">Tel: +27 66 20 200 70</span>
                </v-col>
                <v-col cols="12" md="2" class="pt-12">
                    <span class="headInfo mb-5">United Kingdom</span>
                    <span class="infoText">9 Ploughmans Croft, Newport,</span>
                    <span class="infoText mb-5">Shropshire, TF10 7XP, England</span>
                    <span class="infoText">+44 20 3996 3855</span>
                </v-col>
            </v-row>
        </v-col>
    </v-row>

    <v-row no-gutters>
        <v-col cols="12" class="d-flex justify-center align-center py-0">
            <span class="infoText">Copyright © {{ year }} LOGLIVE LTD. All Rights Reserved.</span>
        </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
    props: {
        showContactButton: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            year: new Date().getFullYear()
        }
    },
    methods: {

        goTo(route) {
            if (this.$route.path !== `/${route.toLowerCase()}`) {
                this.$router.push({ path: `/${route.toLowerCase()}` })
                
            }
        },
    }
}
</script>

<style>
.headInfo {
    font: 700 20px/24px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(184, 184, 184);
    display: block;
}

.infoText {
    font: 400 16px/20px DIN_Regular, Arial, Verdana, sans-serif;
    color: rgb(184, 184, 184);
    display: block;
}
.logo,
.logoBig {
    display: inline-block;
    letter-spacing: 4px;
    color: #fff;
    cursor: pointer;
}

.logo {
    font: 400 20px/26px Roboto, Helvetica, sans-serif;
    font-size: 20px;

}

.logoBig {
    font: 400 48px/56px Roboto, Helvetica, sans-serif;
    font-size: 60px;
}

</style>