<template>
    <v-container fluid style="height: auto;">
        <v-row class="d-flex align-center justify-center pb-15">
            <v-col cols="12" >
                <div class="mainCard"></div>
                <div :class="isMobile ? 'headMobile' : 'head'">
                    <div class="loader text-center ">
                        <span class="bigText">The</span>
                        <div class="words">
                            <span class="word">Leaders</span>
                            <span class="word">Founders</span>
                            <span class="word">Visionaries</span>
                            <span class="word">Chieftains</span>
                            <span class="word">Pioneers</span>
                        </div>
                    </div>
                    <div>
                        <p class="infoText" style="text-align: center;">Devin Butler, Jarryd Pearce, and Richard Lynas are the visionary founders of Loglive, each bringing a distinct set of skills and passions that synergize to form a dynamic and innovative company. Devin, the “Víðsýni Innovator,” challenges norms and introduces groundbreaking ideas, mirroring the far-seeing and open-minded essence of his title. His knack for strategizing and breaking new ground in the digital realm is complemented by his playful versatility, much like a Viking skald known for storytelling and entertainment.</p>
                        <p class="infoText" style="text-align: center;">Jarryd, the “Ríkisbóndi of Realms,” is the mastermind behind the intricate systems that form the backbone of Loglive. His role as the Chieftain of Code is not just about writing lines of software; it’s about overseeing the creation and lifecycle of vast digital landscapes, akin to a powerful landowner managing his domains. His passion for padel and relentless pursuit of emerging technologies infuse Loglive with a forward-thinking and robust architectural foundation.</p>
                        <p class="infoText" style="text-align: center;">Richard, with his keen ability to bring ideas into fruition, stands as a testament to the practical and results-driven ethos of Loglive. His multifaceted life, balancing the precision of hockey, the rhythm of dance, and a passion for culinary arts, brings a unique perspective to the company. Richard’s diverse interests and skills ensure that Loglive is not just about technology but also about creativity, teamwork, and a zest for life.</p>
                        <p class="infoText" style="text-align: center;">Together, Devin’s innovative strategies, Jarryd’s architectural mastery, and Richard’s pragmatic creativity forge a formidable team. Loglive is not just a company; it’s a melting pot of visionary thinking, technical excellence, and a vibrant culture, poised to make a significant impact in the digital world.</p>
                    </div>
                </div>
            </v-col>

            <v-col>
                <div :class="$vuetify.breakpoint.mobile ? 'headMobile' : 'head'">
                    <v-row class="d-flex justify-center align-center" >
                        <v-col cols="12" md="3" class="pb-12" style="contain: content; min-height: 360px; min-width: 325px;">
                            <v-img lazy-src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg?size=338&ext=jpg&ga=GA1.1.2082370165.1710547200&semt=ais" min-width="300px" min-height="300px" src="../../public/Team/Richard-Lynas.png"></v-img>
                            <v-card class="teamInfo">
                                <span class="name">Richard Lynas</span>
                                <span class="roles">Chieftain</span>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="3" class="pb-12" style="contain: content; min-height: 360px; min-width: 325px;">
                            <v-img lazy-src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg?size=338&ext=jpg&ga=GA1.1.2082370165.1710547200&semt=ais" min-width="300px" min-height="300px" src="../../public/Team/Devin-Butler-2.jpg"></v-img>
                            <v-card class="teamInfo">
                                <span class="name">Devin Butler</span>
                                <span class="roles">Master Saga Strategist</span>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="3" class="pb-12" style="contain: content; min-height: 360px; min-width: 325px;">
                            <v-img lazy-src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg?size=338&ext=jpg&ga=GA1.1.2082370165.1710547200&semt=ais" min-width="300px" min-height="300px" src="../../public/Team/Jarryd-Pearce.jpg"></v-img>
                            <v-card class="teamInfo">
                                <span class="name">Jarryd Pearce</span>
                                <span class="roles">Chieftain of Code</span>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <v-row class="pb-15">
            <v-col cols="12">
                <div :class="isMobile ? 'headMobile' : 'head'">
                    <div class="loader text-center ">
                        <span class="bigText">The</span>
                        <div class="words">
                            <span class="word">Legends</span>
                            <span class="word">Team</span>
                            <span class="word">Builders</span>
                            <span class="word">Developers</span>
                            <span class="word">Warriors</span>
                        </div>
                    </div>
                    <div>
                        <p class="infoText" style="text-align: center;">Among our diverse array of qualifications and expertise lies a highly qualified team whose collective knowledge in agriculture, data, and coding elevates our business and development strategies to unprecedented heights. This unique fusion of specialized skills sets the foundation for innovation and excellence, driving Loglive forward in a class of its own. Our qualifications include, but are not limited to:</p>
                        <p class="infoText" style="text-align: center;">BCom Investment Management | Holder of a National Certificate in IT Systems Development and a Microsoft Certified Solutions Developer (MCSD) | BScHon Agricultural Economics and AgriBusiness Management | BSC Youtube | B.Sc. Comp. Sc. & Mathematics | B.Ed | BCom Management Accounting | BCom Management Accounting</p>
                    </div>
                </div>
            </v-col>

            <v-col>
                <div :class="isMobile ? 'headMobile' : 'head2'">
                    <v-row class="d-flex justify-center align-center">
                        <v-col cols="12" md="2" lg="2" class="pb-12" style="contain: content; min-height: 276px; min-width: 250px;">
                            <v-img lazy-src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg?size=338&ext=jpg&ga=GA1.1.2082370165.1710547200&semt=ais" min-width="276px" min-height="250px" src="../../public/Team/Zenon-Eransmus.jpg"></v-img>
                            <v-card class="teamInfo">
                                <span class="name">Zenon Erasmus</span>
                                <span class="roles">Code Warrior</span>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="2" lg="2" class="pb-12" style="contain: content; min-height: 276px; min-width: 250px;">
                            <v-img lazy-src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg?size=338&ext=jpg&ga=GA1.1.2082370165.1710547200&semt=ais" min-width="276px" min-height="250px" src="../../public/Team/Ross-Abrahams.jpg"></v-img>
                            <v-card class="teamInfo">
                                <span class="name"> Ross Abrahams</span>
                                <span class="roles">Code Warrior</span>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="2" lg="2" class="pb-12" style="contain: content; min-height: 276px; min-width: 250px;">
                            <v-img lazy-src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg?size=338&ext=jpg&ga=GA1.1.2082370165.1710547200&semt=ais" min-width="276px" min-height="250px" src="../../public/Team/Michael-Becker.jpg"></v-img>
                            <v-card class="teamInfo">
                                <span class="name"> Michael Becker</span>
                                <span class="roles">Code Warrior</span>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="2" lg="2" class="pb-12" style="contain: content; min-height: 276px; min-width: 250px;">
                            <v-img lazy-src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg?size=338&ext=jpg&ga=GA1.1.2082370165.1710547200&semt=ais" min-width="276px" min-height="250px" src="../../public/Team/Lourens-Matthysen.jpg"></v-img>
                            <v-card class="teamInfo">
                                <span class="name"> Lourens Matthysen</span>
                                <span class="roles">Code Warrior</span>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <FooterComponent></FooterComponent>
    </v-container>
</template>

<script>
import FooterComponent from './FooterComponent.vue';
export default {
    name: 'TeamPage',

    components: {
        FooterComponent
    },
    
    data: () => ({}),

    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        }
    },
};
</script>

<style scoped>

.logo {
    display: inline-block;
    letter-spacing: 4px;
    font-size: 20px;
    font: 24px/26px Roboto,Helvetica,sans-serif;
    color: #fff;
    cursor: pointer;
}
.mainCard {
    background: url(../../public/wave.png) no-repeat 0px 26vh fixed;
    /* background-color: black !important; */
    opacity: 0.2;
    background-size: cover;
    height: 100%;
    width: 100vw;
    position: absolute;
}

.bottomInfo {
    background-color: rgba(0, 0, 0, 1) !important;
    height: 24vh;
    width: 100vw;
    position: relative;
    bottom: 0;
}

.teamInfo {
    height: 7vh;
    width: 70%;
    position: fixed;
    bottom: 0;
    left: 50%;
    right: auto;
    transform: translate(-50%, 0%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.first {
    background-color: rgba(0, 0, 0, 0) !important;
    height: 40vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.head {
    background-color: rgba(0, 0, 0, 0) !important;
    width: 66.66%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-inline: auto;
}

.head2 {
    background-color: rgba(0, 0, 0, 0) !important;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-inline: auto;
}

/* .head {
    height: 50vh;
}

.head2 {
    height: 30vh;
} */

.firstMobile {
    background-color: rgba(0, 0, 0, 0) !important;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headMobile {
    background-color: rgba(0, 0, 0, 0) !important;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
}

.bigText {
  font: 700 42px/54px DIN_Regular,Arial,Verdana,sans-serif;
  color: rgb(184, 184, 184);
}

.headInfo {
  font: 700 20px/24px DIN_Regular,Arial,Verdana,sans-serif;
  color: rgb(184, 184, 184);
  display: block;
}

.name {
text-align: center;
  font: 700 20px/24px DIN_Regular,Arial,Verdana,sans-serif;
}

.roles {
    font: 400 16px/20px DIN_Regular,Arial,Verdana,sans-serif;
}

.parentCard {
  /* color used to softly clip top and bottom of the .words container */
  --bg-color: #212121;
  background-color: var(--bg-color);
  padding: 1rem 2rem;
  border-radius: 1.25rem;
}
.loader {
  color: rgb(124, 124, 124);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 50px;
  padding: 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 8px;
}

.words {
  overflow: hidden;
  position: relative;
}
.words::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    var(--bg-color) 10%,
    transparent 30%,
    transparent 70%,
    var(--bg-color) 90%
  );
  z-index: 20;
}

.word {
  display: block;
  height: 100%;
  font: 700 42px/54px DIN_Regular,Arial,Verdana,sans-serif;
  padding-left: 16px;
  color: rgb(184, 184, 184);
  animation: spin_4991 12s infinite;
}

@keyframes spin_4991 {
    0%, 100% {
  -webkit-transform: translateY(-400%);
  transform: translateY(-400%);
}

10% {
  -webkit-transform: translateY(-102%);
  transform: translateY(-102%);
}

25% {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

35% {
  -webkit-transform: translateY(-202%);
  transform: translateY(-202%);
}

50% {
  -webkit-transform: translateY(-200%);
  transform: translateY(-200%);
}

60% {
  -webkit-transform: translateY(-302%);
  transform: translateY(-302%);
}

75% {
  -webkit-transform: translateY(-300%);
  transform: translateY(-300%);
}

85% {
  -webkit-transform: translateY(-402%);
  transform: translateY(-402%);
}
}

</style>