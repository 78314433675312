<template>
  <v-app class="backgroundMain">
    <!-- Nav Drawer -->
    <v-navigation-drawer
      dark
      v-model="drawer"
      app
      temporary
      right
      class="navDrawer"
      floating
    >
      <v-list-item> 
        <v-list-item-content>
          <span @click="goTo('home')" class="logo">LØGLIVE</span>
          
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <template v-for="(item, i) in menuItems">
          <v-list-item
            v-if="item.visible"
            :key="i"
            link
            @click="goTo(item.route)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list-item href="https://app.loglive.ai/" target="_blank">
          <v-list-item-content>
              <v-list-item-title>Portal</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- App Bar -->
    <v-app-bar
      app
      class="topBar"
      dark
    >
      <!-- <div :class="mobile ? 'd-flex align-center' : 'd-flex align-center pl-12'" >
        <span @click="goTo('home')" class="logo">LØGLIVE</span>
        <div :class="mobile ? '' : 'ml-15'">
          <v-btn plain small class="mb-1" @click="goTo('about')">About</v-btn>
          <v-btn plain small class="mb-1" @click="goTo('team')">Team</v-btn>
          <v-btn plain small class="mb-1" @click="goTo('news')">News</v-btn>
          <v-btn plain small class="mb-1" @click="goTo('contact')">Contact</v-btn>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn plain small class="mb-1" @click="goToPortal">Portal</v-btn> -->
      <span @click="goTo('home')" class="logo">LØGLIVE</span>
      <template v-if="isMobile">
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      </template>
      <template v-else>
          <div v-for="(item, i) of menuItems" :key="i" >
            <v-btn v-if="item.visible" :key="i" plain small @click="goTo(item.route)">{{ item.label }}</v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn plain small class="ml-4" target="_blank" href="https://app.loglive.ai/">Portal</v-btn>
      </template>
    </v-app-bar>

    <!-- Views -->
    <v-main>
      <router-view class="mx-0 px-0" />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    menuItems: [
      {
        label: 'About',
        route: 'about',
        visible: false
      },
      {
        label: 'Team',
        route: 'team',
        visible: true
      },
      {
        label: 'News',
        route: 'news',
        visible: true
      },
      {
        label: 'Contact',
        route: 'contact',
        visible: true
      }
    ]
  }),

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },

  methods: {
    goToPortal() {
      window.location.href = 'https://app.loglive.ai/';
    },
    goToHome() {
      window.location.href = 'https://loglive.io/';
    },
    goTo(route) {
      if(this.$route.name !== route) {
        this.$router.push({ name: `${route.toLowerCase()}` });
      }
    }
  },
};
</script>

<style scoped>
.backgroundMain {
  background-color: black !important;
}

.topBar {
  background-color: rgba(0, 0, 0, 0.822) !important;
  /* height: 10vh !important; */
}

.topBar ::v-deep .v-toolbar__content {
  /* height: 10vh !important; */
}

.navDrawer {
  background-color: rgba(0, 0, 0, 0.94) !important;
  border: none;
}
.infoText {
  font: 400 16px/20px DIN_Regular,Arial,Verdana,sans-serif;
  color: rgb(184, 184, 184);
  display: block;
}

.headInfo {
  font: 700 20px/24px DIN_Regular,Arial,Verdana,sans-serif;
  color: rgb(184, 184, 184);
  display: block;
}

.logo {
  margin: 0 2vw 0 2vw;
  letter-spacing: 4px;
  font-size: 20px;
  font: 24px/26px Roboto,Helvetica,sans-serif;
  color: #fff;
  cursor: pointer;
}
</style>
