import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import HomePageVue from './components/HomePage.vue'
import AboutPageVue from './components/AboutPage.vue'
import TeamPageVue from './components/TeamPage.vue'
import ContactPageVue from './components/ContactPage.vue'
import NewsPageVue from './components/NewsPage.vue'


Vue.config.productionTip = false

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePageVue
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPageVue
  },
  {
    path: '/team',
    name: 'team',
    component: TeamPageVue
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPageVue
  },
  { 
    path: '/news',
    name: 'news',
    component: NewsPageVue 
  }
]

const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})

Vue.use(VueRouter)


new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
