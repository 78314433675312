import Vue from 'vue';
import Vuetify from 'vuetify/lib'
import 'material-design-icons-iconfont/dist/material-design-icons.css'


Vue.use(Vuetify);

export default new Vuetify({
    icons: {
      iconfont: 'md', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
      options: { customProperties: true },
      // },
      themes: {
        dark: {
          // primary: "#ff6666",
          primary: "#fff",
          // secondary: "#f7b568",
          secondary: "#fff",
          tertiary: '#fff',
          // background: '#181818',
          redPop: '#E37169',
          background: '#000',
          backgroundAccent: '#313846',
          primaryText: '#ffffff',
          greyRaised: "#fff",
          // greyBase: "#3d3d3d",
          greyBase: "#fff",
          greyContrast: "#fff",
          toolbar: "#212121",
          topBar: '#000',
          component: "#000",
          danger: "#eb4034",
          modal: '#3d3d3d',
          edit: '#4287f5',
          contrastText: '#fff'
  
        },
      }
    }
  });